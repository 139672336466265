import { format } from "date-fns";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { setAdminKitUser } from "../../../redux/features/authStateSlice";
const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
function convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });
    console.log(result);
    //return null;
    return result;
}
function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    console.log("csv", csv)
    if (csv === null) return;
    const current = format(new Date(), 'dd-MM-yyyy')
    const filename = `user-list-${current}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}
function BusinessUserList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const postPerPage = 10;
    const [isPaginate, setisPaginate] = useState(false)
    const [isCPLoading, setIsCPLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPostCount, setTotalPostCount] = useState(0);
    const [totalPostPages, settotalPostPages] = useState(0);
    const [kitUserData, setKitUserData] = useState([]);
    const adminKitUser = useSelector((state) => state.auth.kituser);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Full Name',
            sortable: true,
            selector: row => {
                if (row.user !== null) {
                    if (row.user.fullname !== '') {
                        return (row.user.fullname);
                    }
                    return (<Badge color="failure">No Name</Badge>)
                } else {
                    return (<Badge color="failure">No User Found</Badge>)
                }
            },
            maxWidth: "100px",
        },
        {
            name: 'Email',
            sortable: true,
            selector: row => {
                if (row.user !== null) {
                    if (row.user.email !== '') {
                        return (row.user.email);
                    }
                    return (<Badge color="failure">No Email</Badge>)
                } else {
                    return (<Badge color="failure">No User Found</Badge>)
                }
            },
            maxWidth: "100px",
        },
        {
            name: 'Phone',
            sortable: true,
            selector: row => {
                if (row.user !== null) {
                    if (row.user.mobile !== '') {
                        return (row.user.mobile);
                    }
                    return (<Badge color="failure">No Phone</Badge>)
                } else {
                    return (<Badge color="failure">No User Found</Badge>)
                }
            },
            maxWidth: "100px",
        },
        {
            name: 'Kit Category',
            sortable: true,
            selector: row => {
                if (row.kit !== null) {
                    if (Object.keys(row.kit.category).length > 0) {
                        return (
                            <>
                                <Badge color="info">Category: {row.kit.category.name}</Badge>
                            </>
                        );
                    }
                    else {
                        return (
                            <>
                                <Badge color="failure">No Catgeoy</Badge>
                            </>
                        );
                    }
                } else {
                    return (<Badge color="failure">No Kit Found</Badge>)
                }
            },
        },
        {
            name: 'Kit Qr Code',
            sortable: true,
            selector: row => {
                if (row.kit !== null) {
                    if (Object.keys(row.kit.qrcode).length > 0) {
                        return (
                            <>
                                <Badge color="info" className="mb-2">Qr Code: {row.kit.qrcode.qr_sr_no}</Badge>
                            </>
                        );
                    }
                    else {
                        return (
                            <>
                                <Badge color="failure">No QR Code</Badge>
                            </>
                        );
                    }
                } else {
                    return (<Badge color="failure">No Kit Found</Badge>)
                }
            },
        },
    ]

    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }
    const getNextData = (page) => {
        setIsCPLoading(true);
        AxiosMain.get(`/kits-users/?page=${page}`, config).then((res) => {
            if (res.status === 200) {
                // if (page === 1) {
                    dispatch(setAdminKitUser(res.data));
                //     dispatch(setAdminKitUser(res.data));
                // }
                // else {
                //     const mergeData = [...adminKitUser.results, ...res.data.results];
                //     const uniqueNames = mergeData.filter((val, id, array) => array.indexOf(val) === id)
                //     const lastval = getUnique(uniqueNames, "id");
                //     const newdata = { ...adminKitUser, results: lastval };
                //     dispatch(setAdminKitUser(newdata));
                // }
                setCurrentPage(page);
                setIsCPLoading(false);
                //console.log("Data save in redux of page ", page);
            }
        }).catch((e) => {
            console.log("Error While getting Kit user", e.response);
            // setIsCPLoading(false);
        })
    }
    const setDataintable = () => {
        if (Object.keys(adminKitUser).length > 0) {
            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const saveresult = parseInt(adminKitUser.results.length);
            const tempData = adminKitUser.results.slice(0, 10);
            setKitUserData(tempData);

            // if (indexOfFirstPost === 0 && indexOfLastPost === saveresult) {
            //     const tempData = adminKitUser.results.slice(indexOfFirstPost, indexOfLastPost);
            //     setKitUserData(tempData);
            // } else {
            //     const tempData = adminKitUser.results.slice(indexOfFirstPost, saveresult);
            //     setKitUserData(tempData);
            // }

            // if (indexOfFirstPost > saveresult && indexOfLastPost === saveresult) {
            //     const tempData = adminKitUser.results.slice(indexOfFirstPost, indexOfLastPost);
            //     setKitUserData(tempData);
            // } else {
            //     const tempData = adminKitUser.results.slice(indexOfFirstPost, saveresult);
            //     setKitUserData(tempData);
            // }
        }
    }
    useEffect(() => {
        if (Object.keys(adminKitUser).length === 0) {
            getNextData(currentPage);
        }
        if (Object.keys(adminKitUser).length > 0) {
            setTotalPostCount(adminKitUser.count);
            settotalPostPages(Math.ceil(adminKitUser.count / 10));
            setDataintable();
        }

    }, [adminKitUser])
    useEffect(() => {
        if (kitUserData.length > 0) {
            setIsCPLoading(false);
        }
    }, [kitUserData])

    useEffect(() => {
        if (totalPostPages !== 0) {
            getNextData(currentPage);
            // if (currentPage === 1) {
            //     if (Object.keys(adminKitUser).length > 0) {
            //         setDataintable();
            //     } else {
            //         getNextData(currentPage);
            //     }
            // } else {
            //     if (Object.keys(adminKitUser).length > 0) {
            //         const indexOfLastPost = currentPage * postPerPage;
            //         const indexOfFirstPost = indexOfLastPost - postPerPage;
            //         const saveresult = parseInt(adminKitUser.results.length);
            //         if (indexOfFirstPost === saveresult) {
            //             getNextData(currentPage);
            //         } else {
            //             setDataintable();
            //         }

            //     }
            // }
        }
    }, [currentPage, totalPostPages]);

    const sortDataCSV = (kitData) => {
        if (kitData.count > 0) {
            console.log("kitData ", kitData.results)
            let output = []
            kitData.results.map((item) => {
                let data = {};
                data['Kit Registeration Date'] = format(new Date(item.activated_at), 'dd-MM-yyyy H:M');
                if (item.kit !== null) {
                    if (Object.keys(item.kit.category).length > 0) {
                        data['Kit Category'] = item.kit.category.name;
                    }
                    else {
                        data['Kit Category'] = "No Category";
                    }
                    if (Object.keys(item.kit.qrcode).length > 0) {
                        data['Kit Number'] = item.kit.qrcode.qr_sr_no;
                    }
                    else {
                        data['Kit Number'] = "No QR Code"
                    }
                }
                else {
                    data['Kit Category'] = "No Category";
                    data['Kit Number'] = "No QR Code"
                }
                if (item.user !== null) {
                    console.log('item',item.user)
                    if (item.user.fullname !== '') {
                        data.Name = item.user.fullname
                    }
                    else {
                        data.Name = "No Name"
                    }
                    if (item.user.email !== '') {
                        data.Email = item.user.email
                    } else {
                        data.Email = "No Email";
                    }

                    if (item.user.mobile !== '') {
                        data['Phone'] = item.user.mobile
                    }
                    else {
                        data['Phone'] = "No Mobile"
                    }
                    if (item.user.schoolname !== '') {
                        data.Schoolname = item.user.schoolname
                    } else {
                        data.Schoolname = "No SchoolName";
                    }
                    
                    if (item.user.state !== '') {
                        data.State = item.user.state
                    } else {
                        data.State = "No State";
                    }
                    if (item.user.city !== '') {
                        data.City = item.user.city
                    } else {
                        data.City = "No City";
                    }
                    if (item.user.address !== '') {
                        data.Address = item.user.address
                    } else {
                        data.Address = "No Address";
                    }
                    if (item.user.postal_pincode !== '') {
                        data.Postal_pincode = item.user.postal_pincode
                    } else {
                        data.Postal_pincode = "No PostalPincode";
                    }
                    if (item.user.valid_upto !== '') {
                        data.Valid_upto = item.user.valid_upto
                    } else {
                        data.Valid_upto = "No ValidUpto";
                    }
                }
                else {
                    data.Name = "No Name";
                    data['Phone'] = "No Mobile";
                    data.Email = "No Email";
                    data.Schoolname = "No SchoolName";
                    data.State = "No State";
                    data.City = "No City";
                    data.Address = "No Address";
                    data.Postal_pincode = "No PostalPincode";
                    data.Valid_upto = "No ValidUpto";             
                }
                output.push(data);
            })
            downloadCSV(output);
        }
    }
    return (
        <div className="bg-white py-5 px-3 mb-[30px]">
            <div className='md:w-full mb-3 w-full mx-auto'>
                <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
                    <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
                        <div className="flex justify-between">
                            <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Users List</h2>
                        </div>

                        <LoadingComponent isLoading={isCPLoading} component={(
                            <DataTable
                                className="cm-category-admin-table"
                                columns={columnsLable}
                                data={kitUserData}
                                paginationPerPage={10}
                                pagination={isPaginate}
                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                actions={<Export onExport={() => sortDataCSV(adminKitUser)} />}
                            />
                        )} />
                    </div>
                </div>
                {
                    totalPostPages > 1 && (
                        <div className="flex justify-center mt-2">
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={(data) => setCurrentPage(data)}
                                showIcons={true}
                                totalPages={totalPostPages} />
                        </div>
                    )
                }

            </div>
        </div>
    )
}
export default BusinessUserList