import { Button, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteBin3FillIcon from "remixicon-react/DeleteBin3FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import Swal from "sweetalert2";
import AxiosMain from "../../../Components/AxiosMain";
import { setAdminVideos } from "../../../redux/features/authStateSlice";
import LoadingComponent from "../../../Components/LoadingComponent";
function AdminVideosList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPaginate, setisPaginate] = useState(false);
    const [isCPLoading, setIsCPLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [adminvideoData, setAdminvideoData] = useState([]);
    const adminAllVideos = useSelector((state) => state.auth.videos);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const deleteVideo = (id) => {
        AxiosMain.delete(`/kits/video/${id}`, config).then((res) => {
            console.log(res);
            if (res.status === 204 && res.data === '') {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                getvideoData(1);
            }
        }).catch((e) => {
            const { response } = e;
            console.log("error", response);
        })
    }
    const deleteAction = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteVideo(id);
            }
        })
    }
    const getvideoData = (data) => {
        setIsCPLoading(true);
        AxiosMain.get(`/kits/video/?page=${data}`, config).then((res) => {
            if (res.status === 200) {
                setIsCPLoading(false);
                if (res.data.count > 0) {
                    dispatch(setAdminVideos(res.data));
                }
                setCurrentPage(data)
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Errors ", response);
            setIsCPLoading(false);
        })
    }
    useEffect(() => {
        if (Object.keys(adminAllVideos).length === 0) {
            getvideoData(currentPage);
        } else {
            setIsCPLoading(false);
            const totalPage = adminAllVideos.count;
            setTotalPages(Math.ceil(totalPage / 10));
            if (adminAllVideos.results.length > 0) {
                setAdminvideoData(adminAllVideos.results);
            } else {
                getvideoData(currentPage);
            }

        }
        console.log(adminAllVideos);
    }, [adminAllVideos]);

    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            maxWidth: "200px",
        },
        {
            name: "Action",
            maxWidth: "150px",
            cell: (row) => (
                <div className="my-3 text-right flex">
                    <Button.Group>
                        <Button className="mb-2" onClick={() => navigate(`/videos/edit/${row.id}`, {
                            state: {
                                id: row.id,
                                videoData: row
                            }
                        })}>
                            <Edit2FillIcon className="h-4 w-4" />
                        </Button>
                        <Button className="" color="failure" onClick={() => deleteAction(row.id)}>
                            <DeleteBin3FillIcon className="h-4 w-4" />

                    </Button>
                    </Button.Group>
                </div >
            )
        }
    ];
    return (
        <>
            <div className="bg-white py-5 px-3">
                <div className='md:w-full mb-3 w-full max-w-[800px] mx-auto'>
                    <div className="shadow rounded-[10px] border p-3">
                        <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
                                    <div className="flex justify-between">
                                    <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Video List</h2>
                                <Button color="light" className="mb-2  min-w-[130px]" onClick={() => getvideoData(1)}>
                                            Get Latest Videos
                                        </Button>
                                    </div>
                            <LoadingComponent
                                isLoading={isCPLoading}
                                component={(
                                    <DataTable
                                        className="cm-category-admin-table"
                                        columns={columnsLable}
                                        data={adminvideoData}
                                        paginationPerPage={10}
                                        pagination={isPaginate}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                            />
                                )}
                            />

                        </div>
                    </div>
                    {
                        totalPages > 1 && (
                            <div className="flex justify-center mt-2">
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={(data) => getvideoData(data)}
                                    showIcons={true}
                                    totalPages={totalPages} />
                            </div>
                        )
                    }
                </div>
            </div>


        </>
    )
}

export default AdminVideosList;