import { format } from 'date-fns';
import { Button, Pagination, Select } from 'flowbite-react';
import Print from "rc-print";
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import AxiosMain from '../../Components/AxiosMain';
import LoadingComponent from '../../Components/LoadingComponent';
import button from 'react';
import CopyboardLineIcon from 'remixicon-react/FileCopy2LineIcon';


function AdminLabelPrinting(props) {
  const [category, setCategory] = React.useState(0);
  const [printQTY, setPrintQTY] = useState(0);
  const [isCPLoading, setIsCPLoading] = useState(true);
  const [allcategory, setAllcategory] = useState([]);
  const userauth = useSelector((state) => state.auth.data);
  const [isPaginate, setisPaginate] = useState(false);
  const [QRData, setQRData] = useState([]);
  const [tempSearchData, setTempSearchData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [qrCodeSearch, setQRCodeSearch] = useState("");
  //const allStateData = useSelector((state) => state);
  const [filteredItems, setfilteredItems] = useState(QRData);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [categorycount, setCategorycount]= useState(1);

  const [copySuccess, setCopySuccess] = useState('');


  let printDom = null;
  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${userauth.token.access}`,
    },
  }

  const columnsLable = [
    {
      name: 'id',
      sortable: true,
      selector: row => row.id,
      maxWidth: "100px",
    },
    {
      name: 'Serial No',
      selector: row => row.qr_sr_no,
      sortable: true,
        maxWidth: "500px",
        cell: (row) => (
          <div style={{alignItems: "center",justifyContent: "center", display: "-webkit-flex"}}> 
          <div style={{flex: "0 0 3.33%",padding: "5px"}}>
          <CopyboardLineIcon onClick={() => copyToClipboard(row.id, row.qr_sr_no)}/>
          </div>
          <div style={{flex: "0 0 99.33%",padding: "2px"}}>
          <span className="text-xs font-medium   py-0.5 text-center">{row.qr_sr_no}</span>
          </div>
          </div>
        )
    },
    {
      name: 'Category',
      selector: row => row.qr_category,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: 'Date',
      selector: row => format(new Date(row.genreated_at), 'yyyy-MM-dd'),
      sortable: true,
    },
    // {
    //   name: 'Print Count',
    //   selector: row => row.print_count,
    //   sortable: true,
    // },
    {
      name: 'Used',
      selector: row => row.is_used ?
        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Yes</span>
        :
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">No</span>,
      sortable: true,
    },
    {
      name: "QR Code Image",
      cell: (row) => (
        <div>
          <img src={row.qrcode} width="100" alt={`qr-code-${row.qr_sr_no}`} />
        </div>
      ),
    },
    {
      name: "Action",
      maxWidth: "150px",
      cell: (row) => (
        <Button onClick={() => deleteQRCode(row.id, row.is_used)}>Delete</Button>
      )
    }
  ];

  const copyToClipboard = async (row_id,row_qr_sr_code) =>{
    try{
      await navigator.clipboard.writeText(row_qr_sr_code);
      Swal.fire({
        title: "Done",
        text: "QR Code Copied Successfully!",
        icon: "success",
        showCancelButton: false,
        timer: 2000,
      })
      setCopySuccess('Copied!');
    }
    catch(err){
      Swal.fire({
        title: "Error",
        text: "Failed to copy!",
        icon: "error",
        timer: 2000,
        showConfirmButton: false
      });
      setCopySuccess('Failed to copy!');
    }
  }

  const deleteQRCode = (id, isUsed) => {
  
    if (!isUsed) {
      AxiosMain.delete(`/kits/qr-code/${id}`, config).then((res) => {
       
        if (res.status === 204) {
          Swal.fire({
            title: "Done",
            text: "QR Code Deleted Successfully!",
            icon: "success",
            showCancelButton: false,
            timer: 2000,
          })
          setIsCPLoading(true);
          getAllQRCodeData(1);
        }
      }).catch((e) => {
        const { response } = e;
     
        if (response.status === 404) {
          Swal.fire({
            title: "info",
            text: "No Data  found",
            icon: "info",
            timer: 2000,
            showConfirmButton: false
          });
        }
      })
    } else {
      Swal.fire({
        title: "error",
        text: "Access Denied!",
        icon: "error",
        timer: 2000,
        showConfirmButton: false
      });
    }

  }
  const getAllQRCodeData = (paged) => {
    setIsCPLoading(true);
    let URL = `/kits/qr-code/?ordering=-id&page=${paged}`;
    if (qrCodeSearch !== '') {
      URL = `/kits/qr-code/?page=${paged}&search=${qrCodeSearch}`;
    }
    AxiosMain.get(URL, config).then((res) => {
    
      setIsCPLoading(false);

      if (res.status === 200) {
        if (res.data.results.length > 0) {
          setQRData(res.data);
        }
        if (res.data.results.length > 10) {
          setisPaginate(true);
        }
        setCurrentPage(paged);
      }
    }).catch((e) => {
      const { response } = e;
      setIsCPLoading(false);
     
    })
  }
  useEffect(() => {
    if (qrCodeSearch === '' && tempSearchData.length > 0) {
      setTempSearchData([]);
    }
  }, [tempSearchData, qrCodeSearch])
  useEffect(() => {
    if (Object.keys(QRData).length > 0) {
      const totalPage = QRData.count;
      setTotalPages(Math.ceil(totalPage / 10));
      setfilteredItems(QRData.results);
    } else {
      getAllQRCodeData(1);
    }
  }, [QRData])



  const fetchCategories = async () => {
    let categoryData = [];

    try {
      for (let pagenum = 1; pagenum <= categorycount; pagenum++) {
       

        const res = await AxiosMain.get(`/kits/category/?page=${pagenum}`, config);

        if (res.status === 200) {
          
          let totalcount = Math.ceil(res.data.count / 10);
          setCategorycount(totalcount);
          

          if (res.data.results.length > 0) {
            // Use a Set for efficient deduplication by name
            const uniqueCategories = new Set(categoryData.map(cat => cat.name));

            res.data.results.forEach(val => {
              if (!uniqueCategories.has(val.name)) {
                const category = {
                  id: val.id,
                  name: val.name
                };
                categoryData.push(category);
                uniqueCategories.add(val.name);
              }
            });
            
            setAllcategory([...categoryData]); // Set the accumulated category data

          } else {
            Swal.fire({
              title: "Info",
              text: "No Data Found",
              icon: "info",
              timer: 2000,
              showConfirmButton: false
            });
            break; // Stop the loop if no data is found
          }
        } else {
          throw new Error("Error fetching categories");
        }
      }
    } catch (error) {
      console.error("API error:", error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong while fetching categories.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false
      });
    }
  };



  useEffect(() => {


    AxiosMain.get(`/kits/category/`, config).then((res) => {
      
      let totalcount = (Math.ceil(res.data.count / 10))
      setCategorycount(totalcount)



    })

    fetchCategories();

  },

    [categorycount])
  const getPrintLabed = () => {
    if (printQTY !== 0 && category !== 0) {
      if (printQTY > 99) {
        Swal.fire({
          title: "Error",
          text: "Please Select quantity under 100",
          icon: "error",
          showCancelButton: false,
          timer: 2000,
        })
      } else {
        const values = {
          "category": category,
          "quantity": printQTY
        }
        AxiosMain.post("/kits/qr-code/", values, config).then((res) => {
          // console.log("Respons", res);
          if (res.status === 201) {
            Swal.fire({
              title: "Done",
              text: res.data?.message,
              icon: "success",
              showCancelButton: false,
              timer: 2000,
            })
            getAllQRCodeData(1);
          }
        }).catch((e) => {
          const { response } = e;
          //console.log("While get Print ", response);
        })
      }
    }
    else {
      Swal.fire({
        title: "Error",
        text: "Please Select category and Quantity!",
        icon: "error",
        showCancelButton: false,
        timer: 2000,
      })
    }
  }
  const handlePrint = () => {
    console.log("selectedRows", selectedRows)
    let printQR = [];
    if (selectedRows.length > 0) {
      selectedRows.map((item) => {
        printQR.push(item.id);
      })
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "Print this QR Codes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Print it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //savePrintData(printQR);
        printDom.onPrint();
      }
    })
  }
  // useEffect(() => {
  //   console.log("filteredItems ", filteredItems);
  // }, [filteredItems])
  return (
    <div className=''>
      <div className='bg-white p-2 mb-[50px]'>
        <div className='flex flex-col'>
          <div className='flex flex-col justify-center'>
            <div className='bg-white'>
              <div className='flex flex-col'>
                <h1 className='flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2'>Label Print</h1>
                <div className='flex flex-col lg:flex-row my-4 md:gap-x-5 bg-[#f8f9fc] p-3 rounded-[10px] shadow'>
                  <div className='flex justify-between items-center p-0 lg:px-6 xl:w-1/2 lg:w-1/2 w-full my-2 lg:my-0'>
                    <div className=' '>
                      <h3 className='text-xs lg:text-xl'>Category</h3>
                    </div>
                    <div className=' text-xs lg:text-xl text-[#00000080]'>
                      {/* <Pdrop className='' options={categoryArray} handleDropdown={(value) => setCategory(value)} /> */}
                      {
                        allcategory.length > 0 ? (
                          <Select className='min-w-[150px]' onChange={(e) => setCategory(parseInt(e.target.value))}>
                            <option value="0">Select category</option>
                            {allcategory.map((val, index) => (
                              <option value={val.id} key={`admin-cate-${index}`}>
                                {val.name}
                              </option>
                            ))}
                          </Select>
                        ) : <div>No category Found</div>
                      }

                    </div>
                  </div>
                  <div className='flex items-center p-0 lg:px-6 xl:w-1/2 lg:w-1/2 w-full  my-2 lg:my-0'>
                    <div className=' lg:w-9/12 w-1/2'>
                      <h3 className='text-xs lg:text-xl'>Print Qty</h3>
                    </div>
                    <div className='text-xs lg:text-xl text-[#00000080] lg:w-1/4 w-1/2'>
                      <input type='number' placeholder='Enter Qty' min={1} max={100} onChange={(e) => {
                        const val = e.target.value;
                        setPrintQTY(parseInt(val));
                      }} className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-row justify-center py-4'>
                  {selectedRows.length > 0 && (
                    <Button type="button" className="mr-3" onClick={() => handlePrint()}>Print QR Codes</Button>
                  )}
                  <Button className='flex bg-[#4e73df] text-white text-xs lg:text-xl' onClick={() => getPrintLabed()}>Generate Label</Button></div>
                <div className='shadow rounded-[10px] border p-3'>
                  <LoadingComponent isLoading={isCPLoading} component={(
                    <DataTable
                      columns={columnsLable}
                      data={filteredItems}
                      paginationPerPage={10}
                      pagination={isPaginate}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                      selectableRows
                      onSelectedRowsChange={handleRowSelected}
                      subHeader
                      subHeaderComponent={
                        <div className='flex mb-3  align-center'>
                          <input placeholder='Search Searial No' className='px-5 mr-2 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base' type="text" name="admin-label-qr" value={qrCodeSearch} onChange={(e) => setQRCodeSearch(e.target.value)} />
                          <Button onClick={() => getAllQRCodeData(1)}>Search</Button>
                        </div>
                      }
                    />
                  )} />
                </div>
              </div>
              {
                totalPages > 1 && (
                  <div className="flex justify-center mt-2">
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={(data) => getAllQRCodeData(data)}
                      showIcons={true}
                      totalPages={totalPages} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <Print
          ref={myPrint => printDom = myPrint} lazyRender isIframe={false}
          title="Print QR Codes"
          onEnd={(data) => console.log("Data print end ", data)}
        >
          <div className="cm-print-data">
            {selectedRows.length > 0 && selectedRows.map((item, index) => (
              <p key={`qr-codes-${index}`} className="text-center flex items-center justify-center">
                <div>
                  <h4><strong>Serial No: </strong> {item.qr_sr_no}</h4>
                  <img src={item.qrcode} alt={item.qr_sr_no} />
                </div>
              </p>
            ))}
          </div>
        </Print>
      </div>
    </div>
  )
}

export default AdminLabelPrinting